import { Cookie } from "./utils/Cookie.js"

const HISTORY_TIMEOUT = 15_000

const COOKIE_STAT_NAME = '_bc_stat'
const COOKIE_ID_NAME = '_bc_uuid'
const COOKIE_TIME_EXPIRES = (30 * 24 * 60)        // 30 суток
const COOKIE_STAT_TIME_EXPIRES = 5      // 5 минут для статы

const watchData = {
    his_id: 0,
    stat: {
        clicks: 0,
        scroll: getScroll(),
        time: 0
    },
    timeOpen: Date.now()
}

let uid = 0

let startTime = 0
let reinitFlag = false

async function checkBot(b_c_uid, query) {
    try {
        let data = {
            referrer: document.referrer,
            current: document.location.href,
            b_c_uid
        }

        if (Cookie.get(COOKIE_STAT_NAME)) {
            data = {...data, prev: JSON.parse(Cookie.get(COOKIE_STAT_NAME)) }
        }

        const response = await fetch(`${process.env.PROTOCOL}://${process.env.HOST}/api/v1/checkBot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })
        const json = await response.json()
        console.log(json)

        if (json.status === 'error') {
            Cookie.delete(COOKIE_ID_NAME)
            if (!reinitFlag) {
                reinitFlag = true
                return checkCookie()
            }
            // return console.log('Ошибка')
        }

        if (json.status === 'reinit') {
            Cookie.delete(COOKIE_ID_NAME)
            if (!reinitFlag) {
                reinitFlag = true
                return checkCookie()
            }
        }

        if (json.status === 'ok' || json.status === 'bot') {
            // historyID = json.his_id

            // освежить куки
            Cookie.set(COOKIE_ID_NAME, b_c_uid, { 
                expires: new Date(Date.now() + COOKIE_TIME_EXPIRES * 60000),
            })

            watchData.his_id = json.his_id
            watchData.stat = { ...json.stat }
            startTime = json.stat.time
            updateStat()
            return
        }

        // historyID = json.his_id     // убрать - ботам это не надо



        // window.stop()

        // let redirectStr = window.location.origin + window.location.pathname
        // let index = 0
        // for (const key in query) {
        //     if (!key.trim()) continue

        //     if (Object.hasOwnProperty.call(query, key)) {
        //         const value = query[key];
        //         redirectStr += `${index ? '&' : '?'}${key}=${value}`
        //     }
        //     index++
        // }
        // redirectStr += `${index ? '&' : '?'}showCaptcha`

        // window.location.href = redirectStr
    } catch (error) {
        console.log(error)
    }
}

async function updateHistory() {
    try {
        const response = await fetch(`${process.env.PROTOCOL}://${process.env.HOST}/api/v1/updateHistory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            keepalive: true,
            credentials: 'include',
            body: JSON.stringify({...watchData, uid })
        })
        const json = await response.json()
        // console.log(json)

        if (json.status !== 'ok') {
            Cookie.delete(COOKIE_ID_NAME)
            if (!reinitFlag) {
                reinitFlag = true
                return checkCookie()
            }
        }
    } catch (error) {
        console.log(error)
    }
}

async function checkCookie() {
    try {
        const response = await fetch(`${process.env.PROTOCOL}://${process.env.HOST}/api/v1/checkCookie`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        const json = await response.json()
        
        if (json?.uid) {
            uid = json.uid
            Cookie.set(COOKIE_ID_NAME, json.uid, { 
                expires: new Date(Date.now() + COOKIE_TIME_EXPIRES * 60000),
            })
            await checkBot(json.uid)
        }
    } catch (error) {
        console.log(error)
    }
}

function drawCaptcha() {
    document.querySelector('body').replaceChildren()

    document.querySelector('body').insertAdjacentHTML('beforeend', `
        <div id="root">
            <div class="captcha">
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
                <div class="captcha_circle"></div>
            </div>
            <div class="captcha_instruction"></div>
        </div>
    `)

    const scriptCaptcha = document.createElement('script')
    scriptCaptcha.src = `${process.env.PROTOCOL}://${process.env.HOST}/static/dist/CaptchaPage.js`
    document.head.appendChild(scriptCaptcha)
}

function parse_query_string(query) {
    const vars = query.split("&")
    let query_string = {}

    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=")
        const key = decodeURIComponent(pair.shift())
        const value = decodeURIComponent(pair.join("="))

        if (typeof query_string[key] === "undefined") {
            query_string[key] = value
        } else if (typeof query_string[key] === "string") {
            const arr = [query_string[key], value]
            query_string[key] = arr
        } else {
            query_string[key].push(value)
        }
    }
    return query_string
}

// const query = window.location.search.substring(1)
// const qs = parse_query_string(query)

// if (!qs.hasOwnProperty('showCaptcha')) {
//     checkBot(qs)
// } else {
//     drawCaptcha()
// }

document.addEventListener('click', (e) => {
    watchData.stat.clicks++
    updateStat()
})

document.addEventListener('scroll', (e) => {
    watchData.stat.scroll += parseInt(Math.abs(getScroll() - watchData.stat.scroll) / 100)
    updateStat()
})

function getScroll() {
    return document.documentElement.scrollTop - document.documentElement.clientTop || 0
}

if (!Cookie.get(COOKIE_ID_NAME)) {
    checkCookie()
} else {
    uid = Cookie.get(COOKIE_ID_NAME)
    checkBot(Cookie.get(COOKIE_ID_NAME))
}

setInterval(() => {
    if (watchData.his_id) updateHistory()
}, HISTORY_TIMEOUT)

function updateStat() {
    Cookie.set(COOKIE_STAT_NAME, JSON.stringify(watchData), { 
        expires: new Date(Date.now() + COOKIE_STAT_TIME_EXPIRES * 60000),    // cookie на 5 минут
    })
}

setInterval(() => {
    if (watchData.his_id) {
        watchData.stat.time = startTime + parseInt(((Date.now() - watchData.timeOpen) / 1000))
        updateStat()
    }
}, 3000)